import http from "../utils/http-common";

class ExchangeRatesDataService {
  async getAll() {
    const resp = await http.get("/exchange-rates");
    // resp.data.rows = resp.data.rows.map((r) => { return { ...r, name: r.name + ` (${r.rate})` } })
    return resp;
  }

  get(id) {
    return http.get(`/exchange-rates/${id}`);
  }

  getByDate(date) {
    return http.get(`/exchange-rates/date/${date}`);
  }

  getNbs() {
    return http.get(`/exchange-rates/nbs`);
  }

  getNbsByDate(date) {
    return http.get(`/exchange-rates/nbs/${date}`);
  }

  create(data) {
    return http.post("/exchange-rates", data);
  }

  update(id, data) {
    return http.put(`/exchange-rates/${id}`, data);
  }

  delete(id) {
    return http.delete(`/exchange-rates/${id}`);
  }
}

export default new ExchangeRatesDataService();