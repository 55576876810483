<template>
  <div class="list row">
    <h2>Kursne liste</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      />
      <button
        type="button"
        class="btn btn-success bi bi-plus"
        @click="addData"
      />
      <button
        type="button"
        class="btn btn-primary bi bi-box-arrow-in-down"
        @click="getFromNBS"
      >
        Preuzmi
      </button>
    </div>
    <data-table
      url="exchange-rates"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      ref="datatable"
      @yes="deleteData"
      name="exchange-rates"
    >
    </data-table>
  </div>
</template>

<script>
import ExchangeRatesDataService from "../../services/exchange-rates.service";
import DataTable from "../widgets/DataTable.vue";
import dateUtils from "../../utils/date-common";
import ExchangeRatesForm from "./ExchangeRatesForm.vue";
import exchangeUtils from "./exchange-utils.js";

export default {
  name: "exchange-rates",
  components: {
    DataTable,
  },
  data() {
    return {
      columns: [
        "exchange_rate_date",
        "eur",
        "chf",
        "gbp",
        "usd",
        "aud",
        "sek",
        "eur_adiko",
        "chf_adiko",
        "gbp_adiko",
        "usd_adiko",
        "aud_adiko",
        "sek_adiko",
        "edit",
      ],
      headers: {
        exchange_rate_date: "Datum Kursa",
        eur: "EUR",
        chf: "CHF",
        gbp: "GBP",
        usd: "USD",
        aud: "AUD",
        sek: "SEK",
        eur_adiko: "EUR Adiko",
        chf_adiko: "CHF Adiko",
        gbp_adiko: "GBP Adiko",
        usd_adiko: "USD Adiko",
        aud_adiko: "AUD Adiko",
        sek_adiko: "SEK Adiko",
        edit: "",
      },
      templates: {
        exchange_rate_date(h, row) {
          return dateUtils.formatDate(row.exchange_rate_date);
        },
      },
      actions: {
        edit: { commp: ExchangeRatesForm },
        delete: {
          deleteData: this.deleteData,
          color: "danger",
          icon: "bi-trash",
        },
        // print: null,
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    async getFromNBS() {
      const res = await exchangeUtils.getFromNBS(
        new Date().toISOString().substring(0, 10).replaceAll("-", "")
      );
      this.$store.commit("app/showModal", {
        modal: ExchangeRatesForm,
        params: {
          data: res,
          title: "Porvrdi novi kurs",
        },
      });
    },
    refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$store.commit("app/showModal", {
        modal: ExchangeRatesForm,
        params: {
          data: {
            exchange_rate_date: new Date().toISOString().substring(0, 10),
          },
          title: "Dodaj novi kurs",
        },
      });
    },
    deleteData(id) {
      ExchangeRatesDataService.delete(id)
        .then((response) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: response.data.message,
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>

<style scoped>
.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}
</style>
