import axios from 'axios';
import http from "../utils/http-common";
// import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_BE_URL + "api/test/";

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { withCredentials: true });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { withCredentials: true },);
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { withCredentials: true });
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  create(data) {
    return http.post("/users", data);
  }

  update(id, data) {
    return http.put(`/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }
}

export default new UserService();
