<template>
  <figure class="text-end m-3">
      <figcaption class="blockquote-footer">
        Kreirano: <cite :key="createdData['id']" v-if="createdData">{{ createdData.firstname }} {{ createdData.lastname }} {{' '}}</cite> <cite v-else> - </cite>  
        <cite v-if="created"> {{ getDate(created) }}</cite> 
        Poslednja izmena: <cite :key="updatedData['id']" v-if="updatedData">{{ updatedData.firstname }} {{ updatedData.lastname }} {{' '}}</cite> <cite v-else> - </cite>
        <cite v-if="updated"> {{ getDate(updated) }}</cite> 
      </figcaption>
    </figure>
</template>
<script>
import dateUtils from "../../utils/date-common";
import UserService from "../../services/user.service";

export default {
  name: "form-futher",
  data() {
    return {
      createdUser: null,
      updatedUser: null,
    };
  },
  components: {

  },
  props: {
    createdBy: {
      type: Number,
      required: false,
    },
    created: {
      type: String,
      required: false,
    },
    updatedBy: {
      type: Number,
      required: false,
    },
    updated: {
      type: String,
      required: false,
    },
  },
  computed: {
    createdData() {
      return this.createdUser ?? {};
    },
    updatedData() {
      return this.updatedUser ?? {};
    },
    
  },
  methods: {
    getDate(date) {
      return dateUtils.formatDateTime(date);
    },
  },
  mounted() {
    if (this.createdBy) {
        UserService.get(this.createdBy).then((createdByRes) => {
            this.createdUser = createdByRes.data;
        });
    }
    if (this.updatedBy) {
        UserService.get(this.updatedBy).then((res) => {
            this.updatedUser = res.data;
        });
    }
  },
};
</script>

<style scoped>
</style>
