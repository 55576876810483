import ExchangeRatesDataService from "../../services/exchange-rates.service";
// import ExchangeRatesForm from "./ExchangeRatesForm.vue";

// import store from "../../store";

export default {
    getFromNBS(date) {
        return ExchangeRatesDataService.getNbsByDate(
            date
        ).then((response) => {
            var data = {
                aud: "0.0000",
                chf: "0.0000",
                eur: "0.0000",
                exchange_rate_date: "",
                gbp: "0.0000",
                sek: "0.0000",
                usd: "0.0000",
            };
            for (let index = 0; index < response.data.length; index++) {
                if (response.data[index]["CurrencyCodeAlfaChar"]._text == "EUR") {
                    const dmy = response.data[index]["Date"]._text.split(".");
                    data["eur"] = response.data[index]["MiddleRate"]._text;
                    data["exchange_rate_date"] = `${dmy[2]}-${dmy[1]}-${dmy[0]}`;
                }
                if (response.data[index]["CurrencyCodeAlfaChar"]._text == "AUD") {
                    data["aud"] = response.data[index]["MiddleRate"]._text;
                }
                if (response.data[index]["CurrencyCodeAlfaChar"]._text == "CHF") {
                    data["chf"] = response.data[index]["MiddleRate"]._text;
                }
                if (response.data[index]["CurrencyCodeAlfaChar"]._text == "GBP") {
                    data["gbp"] = response.data[index]["MiddleRate"]._text;
                }
                if (response.data[index]["CurrencyCodeAlfaChar"]._text == "USD") {
                    data["usd"] = response.data[index]["MiddleRate"]._text;
                }
                if (response.data[index]["CurrencyCodeAlfaChar"]._text == "SEK") {
                    data["sek"] = response.data[index]["MiddleRate"]._text;
                }
            }
            return data;
            // store.commit("app/showModal", {
            //     modal: ExchangeRatesForm,
            //     params: {
            //         data: data,
            //         title: "Porvrdi novi kurs",
            //     },
            // });
        });
    },

}