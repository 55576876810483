<template>
  <div class="page">
  <data-form2
    :key="excKey"
    :data="dataClone"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
    @show="showAdiko"
    @open="openAdiko"
    @getFromNBS="getFromNBS"
  />
  <div v-bind:class="{ active: isActive, hidden: !isActive }">
    <iframe
      src="https://nle.in.rs/kursna/efektiva.htm"
      height="300"
      width="400"
      title="Adiko Kurs"
    />
  </div>
  <hr>
  <form-futher :key="dataClone['id'] + 'foother'" v-if="dataClone" 
    :createdBy="dataClone['createdBy']"
    :created="dataClone['createdAt']"
    :updatedBy="dataClone['updatedBy']"
    :updated="dataClone['updatedAt']"
    />
  </div>
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import ExchangeRatesDataService from "../../services/exchange-rates.service";
import exchangeUtils from "./exchange-utils.js";
import FormFuther from "../users/FormFuther.vue";

export default {
  name: "type-form",
  props: ["data"],
  components: {
    DataForm2,
    FormFuther,
  },
  data() {
    return { message: "", isActive: false, excKey: 0, formData: null };
  },
  computed: {
    // isActive() {
    //   return false;
    // },
    dataClone() {
      return this.formData ? this.formData : { ...this.data };
    },
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [
            { exchange_rate_date: { label: "Datum kursa", type: "date" } },
            {
              add: {
                type: "button",
                text: "NBS",
                emit: "getFromNBS",
                biIcon: "bi-box-arrow-in-down",
                btnColor: "btn-warning",
              },
            },
          ],

          [{ divider: { type: "divider" } }],
          [
            { eur: { label: "EUR", type: "number" } },
            { chf: { label: "CHF", type: "number" } },
            {},
            {},
          ],
          [
            { gbp: { label: "GBP", type: "number" } },
            { usd: { label: "USD", type: "number" } },
            {},
            {},
          ],
          [
            { aud: { label: "AUD", type: "number" } },
            { sek: { label: "SEK", type: "number" } },
            {},
            {},
          ],
          [{ divider: { type: "divider" } }, {}],
          [
            { eur_adiko: { label: "EUR Adiko", type: "number" } },
            { chf_adiko: { label: "CHF Adiko", type: "number" } },
            {},
            {},
          ],
          [
            { gbp_adiko: { label: "GBP Adiko", type: "number" } },
            { usd_adiko: { label: "USD Adiko", type: "number" } },
            {},
            {},
          ],
          [
            { aud_adiko: { label: "AUD Adiko", type: "number" } },
            { sek_adiko: { label: "SEK Adiko", type: "number" } },
            {},
            {},
          ],
          [
            {},
            {},
            {
              show: {
                type: "button",
                text: this.isActive ? " Sakrij Adiko" : " Prikaži Adiko",
                emit: "show",
                biIcon: "bi-bank",
                btnColor: "btn-warning",
              },
            },
            {
              open: {
                type: "button",
                text: " Adiko Banka",
                emit: "open",
                biIcon: "bi-box-arrow-up-right",
                btnColor: "btn-warning",
              },
            },
          ],
          // [{ country: { label: "Drzava", type: "text" } }, {}, {}, {}],
          [{ divider: { type: "divider" } }],
        ],
      };
    },
  },
  methods: {
    async getFromNBS(data) {
      console.log({ data });
      const test = await exchangeUtils.getFromNBS(
        data.exchange_rate_date.replaceAll("-", "")
      );
      console.log({ test });
      console.log({ clone: this.dataClone });
      this.formData = {
        ...test,
        exchange_rate_date: this.dataClone["exchange_rate_date"],
        eur_adiko: this.dataClone["eur_adiko"],
        chf_adiko: this.dataClone["chf_adiko"],
        gbp_adiko: this.dataClone["gbp_adiko"],
        usd_adiko: this.dataClone["usd_adiko"],
        aud_adiko: this.dataClone["aud_adiko"],
        sek_adiko: this.dataClone["sek_adiko"],
      };
      console.log({ clone: this.dataClone });
      this.excKey++;
    },
    saveData(newData) {
      if (newData && newData.id) {
        this.update(newData);
      } else {
        this.create(newData);
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    showAdiko() {
      this.isActive = !this.isActive;
    },
    openAdiko() {
      window.open("https://www.addiko.rs/kursna-lista/", "_blank");
    },
    update(newData) {
      ExchangeRatesDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      ExchangeRatesDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Kursna lista je kreirana!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>

<style scoped>
.active {
  display: block;
  position: absolute;
  top: 220px;
  left: 500px;
}
.hidden {
  display: none;
}
</style>